import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Costatek',
  lang: 'en',
  description: '', // e.g: Welcome to my website
};

export const homeData = {
  title: 'Costatek | Nearshore Software Development Costa Rica',
  description: 'A web development studio based in Costa Rica ready to upscale your team and convert your ideas into reality.'
}

// HERO DATA
export const heroData = {
  title: '',
  name: 'Costatek',
  subtitle: 'Nearshore software development in Costa Rica with over 14+ years experience developing full stack scalable projects.',
  cta: 'Who are we?',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'We are a team of passionate self-starters who are in constant training to be able to provide our clients with the latest technologies and deliver scalable solutions on time and on budget.',
  paragraphTwo: 'We offer part-time and full-time developers to upscale your existing team.  We can also take over and provide you with a full service product development team, either for existing products or completely new ones.',
  paragraphThree: 'Over the years of working with clients, we have come up with a bullet proof tech stack that can be adapted to almost any industry with little to no learning curve.  We are ready to hit the ground running and leverage our 14+ years of experience to make your product excel and succeed.',
  cta: "Contact Us"
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'humans',
    title: 'Humans',
    info: `We designed and developed a multi-tenant psychology support platform to be used in high schools across Costa Rica, Panama and Ecuador.  The platform consists of a web backend to be used by school psychologists and Android and iOS apps to be used by students.`,
    info2: '',
    url: 'http://www.humanscr.com/',
  },
  {
    id: nanoid(),
    img: 'inversso',
    title: 'Inversso',
    info: `Inversso helps Costa Ricans invest in US Stock Exchange.  We did the onboarding complex form where we gather all the regulatory information required and then bundle it, encrypt it, and send it to the broker's API for account creation.`,
    info2: '',
    url: 'https://micuenta.inversso.com/', // if no repo, the button will not show up    
  },
  {
    id: nanoid(),
    img: 'comu',
    title: 'IP Access Control',
    info: 'We built an access control system for gated communities.  It integrates ISONAS hardware (authorized resellers/integrators) via API to grant one-time access codes for visitors. Tenants can also add maintenance requests and reserve ammenities.',
    info2: '',
  },
  // {
  //   id: nanoid(),
  //   img: 'instagramfilter',
  //   title: 'Instagram Filter',
  //   info: 'Yeah, we also do fun stuff.  We did a filter for Sajo McCann in Puerto Rico. They needed a filter ASAP and we were able to make it in a day and deliver.',
  //   info2: '',
  // },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: '',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: '',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: '',
    },
    {
      id: nanoid(),
      name: 'github',
      url: '',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
