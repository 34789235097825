import * as React from "react"
import PropTypes from "prop-types"
import Menu from './Menu/menu'

const Header = () => (
  <header>
    <Menu></Menu>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Costatek`,
}

export default Header
