import React from "react"
import * as menuStyles from "./menu.module.css"
import { Link as LinkScroll } from "react-scroll"
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby"

function myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
        x.className += " responsive";
    } else {
        x.className = "topnav";
    }
}

export default function Menu() {

    return (
        <div className={menuStyles.topnav}>
            <LinkScroll
                to="hero"
                smooth duration={1000}
                className={menuStyles.topnavItemImage}
            >
                <StaticImage width={200} height={50} src="../../images/costatek-logo-horizonal-white.png" alt='Costatek Logo white' />
            </LinkScroll>
            <LinkScroll
                to="contact"
                smooth duration={1000}
                className={menuStyles.topnavItem}
            >Contact</LinkScroll>
            <LinkScroll
                to="projects"
                smooth duration={1000}
                className={menuStyles.topnavItem}
            >
                Projects
            </LinkScroll>
            <LinkScroll
                to="about"
                smooth duration={1000}
                className={menuStyles.topnavItem}
            >
                About Us
            </LinkScroll>
        </div>
    )
}
